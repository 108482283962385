
import { Component, Inject, InjectReactive, Vue } from "vue-property-decorator";
import { API_ENDPOINT_SYMBOL, USER_PROFILE_SYMBOL } from "@/constants";
import TradeUrlInput from "@/components/user/TradeUrlInput.vue";
import HomeHeading from "@/components/home/HomeHeading.vue";
import openURL from 'quasar/src/utils/open-url.js';;
import * as _ from "lodash";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";
import gql from "graphql-tag";
import { SteamBotOfferFragment } from "@/graphql/fragments";

@Component({
  components: {
    TradeUrlInput,
    Amount,
    CurrencyIcon,
    HomeHeading
  },
  meta: {
    title: "Профиль позьзователя"
  },
  apollo: {
    offers: {
      update(data) {
        if (data.getPartnerSteamBotOffers) {
          this.offersPagination.rowsNumber =
            data.getPartnerSteamBotOffers.totalDocs;
        }

        return data.getPartnerSteamBotOffers;
      },
      query: gql`
        query(
          $page: Int!
          $limit: Int
          $sort: String
          $search: String
          $createdAtRange: [DateTime!]
        ) {
          getPartnerSteamBotOffers(
            page: $page
            limit: $limit
            sort: $sort
            createdAtRange: $createdAtRange
            search: $search
          ) {
            docs {
              ...SteamBotOffer
              paymentId
              userId
            }
            totalDocs
          }
        }
        ${SteamBotOfferFragment}
      `,
      variables() {
        return {
          search: this.offersSearch || undefined,
          createdAtRange: this.offersCreatedAtRange || undefined,
          page: this.offersPagination.page,
          limit: this.offersPagination.rowsPerPage,
          sort: this.offersPagination.sortBy
            ? `${this.offersPagination.descending ? "-" : ""}${
                this.offersPagination.sortBy
              }`
            : "-_id"
        };
      },
      skip() {
        return !this.user || !this.user.partner;
      }
    },
    offersStats: {
      update(data) {
        return data.getPartnerSteamBotOffersStats || {};
      },
      query: gql`
        query($search: String, $createdAtRange: [DateTime!]) {
          getPartnerSteamBotOffersStats(
            createdAtRange: $createdAtRange
            search: $search
          )
        }
      `,
      variables() {
        return {
          search: this.offersSearch || undefined,
          createdAtRange: this.offersCreatedAtRange || undefined
        };
      },
      skip() {
        return !this.user || !this.user.partner;
      }
    }
  }
})
export default class Profile extends Vue {
  @InjectReactive(USER_PROFILE_SYMBOL) user;
  @Inject(API_ENDPOINT_SYMBOL) apiEndPoint;
  offersStats = {};
  offersSearch: string = "";
  copyToClipboard = copyToClipboard;
  openURL = openURL;
  columns = [
    {
      name: "_id",
      required: true,
      label: "ID",
      field: "_id",
      align: "left",
      sortable: true,
      classes: "ellipsis bg-primary text-weight-bold",
      style: "max-width: 50px",
      headerClasses: "bg-primary text-white"
    },
    {
      name: "amount",
      align: "center",
      label: "Сумма",
      field: "amount",
      sortable: true,
      classes: "text-weight-bold"
    },
    {
      name: "paymentSystem",
      label: "Платежная система",
      field: "paymentSystem",
      align: "center",
      classes: "text-weight-medium text-uppercase",
      format: v =>
        ({
          QIWI: "Qiwi",
          SKINS: "Покупка скинов",
          CARD: "Карта",
          USDT_TRC20: "USDT (TRC20)",
          OBMENKA_BTC: 'Bitcoin',
          OBMENKA_ETH: 'Etherium',
          OBMENKA_ERC_USDT: 'USDT (ERC20)',
          OBMENKA_TRC_USDT: 'USDT (TRC20)',
          CARD_UAH: 'Карта (UAH)',
          YANDEX: "ЮMoney"
        }[v])
    },
    {
      name: "recipient",
      label: "Реквизиты",
      align: "center",
      classes: "text-weight-medium text-uppercase",
      field: "recipient"
    },
    {
      name: "createdAt",
      label: "Дата",
      align: "center",
      field: "createdAt",
      classes: "text-weight-medium",
      sortable: true
    },
    {
      name: "status",
      label: "Статус",
      field: "status",
      classes: "text-weight-bold ellipsis text-uppercase bg-positive",
      headerClasses: "bg-positive text-white",
      style: "max-width: 50px",
      format: v =>
        ({
          FINISHED: "Оплачено",
          CREATED: "В обработке",
          FAILED: "Произошла ошибка"
        }[v])
    }
  ];
  offersColumns = [
    {
      name: "_id",
      required: true,
      label: "ID",
      field: "_id",
      align: "left",
      sortable: true,
      classes: "ellipsis bg-primary text-weight-bold",
      style: "max-width: 50px",
      headerClasses: "bg-primary text-white"
    },
    {
      name: "paymentId",
      label: "ID платежа в вашей системе",
      field: "paymentId",
      align: "center",
      classes: "ellipsis text-weight-bold",
      style: "max-width: 50px"
    },
    {
      name: "price",
      align: "center",
      label: "Сумма",
      field: "price",
      sortable: true,
      classes: "text-weight-bold"
    },

    {
      name: "userId",
      label: "ID пользователя в вашей системе",
      align: "center",
      classes: "text-weight-medium text-uppercase",
      field: "userId"
    },
    {
      name: "createdAt",
      label: "Дата",
      align: "center",
      field: "createdAt",
      classes: "text-weight-medium",
      sortable: true
    },
    {
      name: "state",
      label: "Статус",
      field: "state",
      classes: "text-weight-bold ellipsis text-uppercase bg-positive",
      headerClasses: "bg-positive text-white",
      style: "max-width: 50px"
    }
  ];
  offersPagination = {
    sortBy: "_id",
    descending: true,
    page: 1,
    rowsPerPage: 5,
    rowsNumber: 0
  };
  offers: {
    docs: any[];
    totalDocs: number;
  } | null = null;

  startTime: number = new Date().setHours(0, 0, 0, 0);
  endTime: number = Date.now() + 24 * 60 * 60 * 1000;

  get offersCreatedAtRange() {
    return [this.fromDate, this.toDate];
  }

  set offersCreatedAtRange(value) {
    if (!value) {
      this.startTime = new Date().setHours(0, 0, 0, 0);
      this.endTime = Date.now() + 24 * 60 * 60 * 1000;
    } else {
      //@ts-ignore
      const { from, to } = value;
      if (from && to) {
        this.fromDate = from;
        this.toDate = to;
      }
    }
  }

  get fromDate() {
    //@ts-ignore
    return this.$options.filters.moment(this.startTime, "YYYY/MM/DD");
  }

  set fromDate(value) {
    this.startTime = new Date(value).getTime();
  }

  get toDate() {
    //@ts-ignore
    return this.$options.filters.moment(this.endTime, "YYYY/MM/DD");
  }
  set toDate(value) {
    this.endTime = new Date(value).getTime();
  }

  async loadMore(query, nextPageToken) {
    await query.fetchMore({
      variables: {
        nextPageToken
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const getUserBalanceWithdraw = _.cloneDeep(prev.getUserBalanceWithdraw);

        getUserBalanceWithdraw.userBalanceWithdraws = [
          ...prev.getUserBalanceWithdraw.userBalanceWithdraws,
          ...fetchMoreResult.getUserBalanceWithdraw.userBalanceWithdraws
        ];

        getUserBalanceWithdraw.hasMore =
          fetchMoreResult.getUserBalanceWithdraw.hasMore;
        getUserBalanceWithdraw.nextPageToken =
          fetchMoreResult.getUserBalanceWithdraw.nextPageToken;

        return Object.assign({}, prev, { getUserBalanceWithdraw });
      }
    });
  }
}
